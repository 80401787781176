Lyte.Mixin.register("crux-criteria-pattern", {//No I18n
	validatePattern : function(str,rowcnt){
		str = str.replace(/#/g,"~");
		var o = str.match(/\(/gi);
		if(o)
		{
			var ol = o.length;
		}
		var c = str.match(/\)/gi);
		if(c)
		{
			var cl = c.length;
		}
		if(ol != cl)
		{
			this.showAlert(_cruxUtils.getI18n("criteria.error.alert.brackets.count.mismatch")); //No I18N
			this.setData('shownAlert',true);//No I18N
			return false;
		}
		var brackStr = str.replace(/(and|or)/g,"#").split(' ').join('').replace(/\n/g,"");
		if((brackStr.indexOf("(#") != -1)||(brackStr.indexOf("#)") != -1))
		{
			this.showAlert(_cruxUtils.getI18n("criteria.error.alert.brackets.invalid")); //No I18N
			this.setData('shownAlert',true);//No I18N
			return false;
		}
		if(brackStr.indexOf("()") != -1)
		{
			this.showAlert(_cruxUtils.getI18n("criteria.error.alert.brackets.invalid")); //No I18N
			this.setData('shownAlert',true);//No I18N
			return false;
		}
		var aaa = brackStr.replace(/[()]/g,"#");
		var noo = str.match(/[0-9]+/g);
		if(noo != null)
		{
			var nooLength = noo.length
			for(var i = 0; i < nooLength; i++)
			{
				if(noo[i] != i+1)
				{
					this.showAlert(_cruxUtils.getI18n("crm.criteria.number.notmatch.check",noo[i]));
					this.setData('shownAlert',true);//No I18N
					return false;
				}
			}
		}
		var cstr = aaa.replace(/[0-9]+/g,"#").replace(/#/g,"");
		if(cstr && cstr.length >=1)//any extra param other than and, or ,brackets
		{
			this.showAlert(_cruxUtils.getI18n('criteria.error.alert.other.params')); //No I18N
			this.setData('shownAlert',true);//No I18N
			return false;
		}
		var n = str.match(/(and|or)/gi) ? str.match(/(and|or)/gi) : []
		if(n)
		{
			if((rowcnt-1) !== n.length)
			{
				this.showAlert(_cruxUtils.getI18n('criteria.error.alert.andor.rowcount.mismatch')); //No I18N
				this.setData('shownAlert',true);//No I18N
				return false;
			}
		}
		// TODO - This will have problem while there are more than 9 criteria rows.
		var nc = aaa.replace(/[0-9]+/g,"$").replace(/#/g,"");
		if(rowcnt != nc.length)
		{
			this.showAlert(_cruxUtils.getI18n('criteria.error.alert.critnum.rowcount.mismatch')); //No I18N
			this.setData('shownAlert',true);//No I18N
			return false;
		}
		str = this.addSpaceBetweenParanthesis(str);
		str = str.replace(/\n/g,"");
		return str;
	},
	addSpaceBetweenParanthesis : function(str){
		var toRet = "";
		str=str.replace(/\s/g,"");
		var strLength=str.length;
		for(var i = 0; i < strLength; i++)
		{
			var ch = str.charAt(i);
			if(ch == "(" || ch == "d" || ch == "r" || ch == "D" || ch == "R")
			{
				toRet = toRet + ch + " ";
			}
			else if(ch == ")" || ch == "a" || ch == "o" || ch == "O" || ch == "A")
			{
				toRet = toRet + " " + ch;
			}
			else
			{
				toRet = toRet + ch;
			}
		}
		return toRet;
	},
	patternChanging : function(func,index,pattern,criteria){
		if(func=='add'){
			criteria='('+criteria+pattern[index-1]+index+')';
		}else if(func == 'remove'){//No I18N
			var t=0;
			var criteria=' '+criteria+' ';
			var replacei=index+' ';
			criteria=criteria.replace(/(and|or)/g, "#");
			criteria = criteria.replace(/#/g, function (match) {
			t++;
			return (t === (index!=1?(index-1):index)) ? "" : match;
			});
			var re = new RegExp(replacei,"g");
			criteria=criteria.replace(re, "");

			var t=0;
			criteria = criteria.replace(/#/g, function (match) {
			t++;
			return (t <= pattern.length) ? pattern[t] : match;
			});

			var t=1;
			criteria = criteria.replace(/[0-9]/g, "#");
			criteria = criteria.replace(/##/g,'#');
			criteria = criteria.replace(/#/g, function (match) {
				return (t <= criteria.match(/#/g).length) ? t++ : match;
			});
		}else if(func =='mod'){//No I18N
			criteria=criteria.replace(/(and|or)/g, "#");
			var t=0;
			criteria = criteria.replace(/#/g, function (match) {
			t++;
			return (t <= pattern.length) ? pattern[t] : match;
			});
		}else if(func == 'specified'){ //no i18n
			criteria=criteria.replace(/(and|or)/g, "#");
			var t=0;
			criteria = criteria.replace(/#/g, function (match) {
			t++;
			return (t <= pattern.length) ? pattern[t] : match;
			});
		}
		criteria=criteria.replace(/ /g,"");
		while(criteria.match(/\(\)/g,"")){
		    criteria=criteria.replace(/\(\)/g,"")
		}
		criteria=this.addSpaceBetweenParanthesis(criteria);
		return criteria;
	},
	changeToDeveloper : function(pattern){
		var and = new RegExp(_cruxUtils.getI18n("and"),'g');
		var or = new RegExp(_cruxUtils.getI18n("or"),'g');
		pattern = pattern.replace(and,'and').replace(or,'or');
		return pattern;
	},
	changeToInternational : function(s){
		s = this.addSpaceBetweenParanthesis(s);
		pattern=s.replace(/and|or/g,function(match){
			if(match == 'and'){
			return _cruxUtils.getI18n("and") //No I18N
			}else if(match == 'or'){ //No I18N
			return _cruxUtils.getI18n("or") //No I18N
			}
		});
		return pattern
	},
	inorder :function(node,s){
		if(node !== null)
	    {
	    	if(node.left!=null){
	            s=s+'(';
	        }
	        s=this.inorder(node.left,s);
	        s=s+node.value;
	        s=this.inorder(node.right,s);
	        if(node.right != null){
	            s=s+')';
	        }
	    }
	    return s;
	}
});
console.error('Please dont use this Mixin, use the crux-criteria-util instead')  //no i18n
